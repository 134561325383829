.app__certification {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__certification-item {
    width: 60%;
    min-height: 320px;
    background-color: var(--white-color);
    display: flex;
    padding: 2rem;
    margin-top: 3rem;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0,0,0,0.1);
    transition: all 0.3s ease-in-out;
    
    img {
        width: 200px;
        height: 200px;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        min-height: 350px;
        width: 1000px;

        img {
            width: 250px;
            height: 250px;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 80%;

        img {
            width: 150px;
            height: 150px;
        }
    }

    @media screen and (max-width: 850px) {
        width: 100%;

        img {
            width: 150px;
            height: 150px;
        }
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;

        img {
            margin-bottom: 1rem;
        }
    }
}

.app__certification-content {
    flex: 1;
    height: 100%;
    padding: 0 2rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    .app__certification-content-header {
        @media screen and (max-width: 600px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    p {
        font-size: 1rem;
        line-height: 2rem;
        color: var(--black-color);
        font-family: var(--font-base);
        margin-bottom: 20px;
        margin-top: 20px;

        @media screen and (max-width: 900px) {
            font-size: 0.9rem;
            line-height: 1.5rem;
        }

        @media screen and (min-width: 2000px) {
            font-size: 1.1rem;
        }
    }

    h4 {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--secondary-color);
    }

    h5 {
        font-size: 1.25rem;
        font-weight: 400;
        color: var(--gray-color);
        margin-top: 5px;
    }

    h6 {
        @media screen and (min-width: 2000px) {
            font-size: 1.25rem;
        }
    }

    a {
        text-decoration: none;
        color: var(--secondary-color);

        @media screen and (min-width: 2000px) {
            font-size: 1.25rem;
        }

        @media screen and (max-width: 600px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    p, h4, h5, h6 {
        @media screen and (max-width: 600px) {
            text-align: center;
        }
    }

    @media screen and (max-width: 600px) {
        padding: 0 0.5rem;
    }
}

.app__certification-certs {
    width: 80%;
    flex-wrap: wrap;
    margin-top: 2rem;

    div {
        width: 150px;
        margin: 1.5rem;

        img {
            cursor: pointer;
            width: 100%;
            height: auto;
            object-fit: cover;
            filter: grayscale(1);
        }

        &:hover {
            img {
                filter: grayscale(0);
            }
        }

        @media screen and (min-width: 2000px) {
            width: 210px;
            margin: 2rem;
        }

        @media screen and (max-width: 600px) {
            width: 120px;
            margin: 1rem;
        }
    }

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}