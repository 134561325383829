.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__about-container {
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px){
        width: 100%;
        flex-direction: column;
    }
}

.app__about-badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: auto;

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

        h1 {
            color:var(--white-color);
            font-size: 2.5rem;

            @media screen and (min-width: 2000px){
                font-size: 4rem;
            }
        }

        h2 {
            color:var(--white-color);
            font-size: 1.8rem;

            @media screen and (min-width: 2000px){
                font-size: 3.5rem;
            }
        }

        p {
            font-size: 1rem;
            font-weight: 500;
            white-space: nowrap;

            @media screen and (max-width: 500px) {
                font-size: 0.8rem;
            }

            @media screen and (min-width: 2000px){
                font-size: 1.6rem;
            }
        }
    }

    .badge-cmp {
        background-color: var(--secondary-color);
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 2rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }
}

.app__about-presentation {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 900px){
        margin-right: 0;
    }
}

.app__about-photo {
    flex-direction: column;
    text-align: center;
    margin: 3rem;
    transition: all 0.3s ease-in-out;

    div {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        margin-bottom: auto;

        img {
            margin-top: 2rem;
            width: 100%;
            height: 100%;
            scale: 1.2;
            border-radius: 50%;
            object-fit: contain;
        }

        @media screen and (min-width: 2000px){
            width: 350px;
            height: 350px;
        }

        @media screen and (max-width: 900px){
            width: 200px;
            height: 200px;
        }
    }
}

.app__about-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    margin-top: auto;
    font-size: 1.1rem;

    p {
        margin: 0.8rem 0;
    }

    @media screen and (max-width: 1200px){
        font-size: 1rem;

        p {
            margin: 0.9rem 0;
        }
    }

    @media screen and (max-width: 900px) {
        font-size: 0.95rem;
    }

    @media screen and (min-width: 2000px){
        font-size: 1.3rem;

        p {
            margin: 1rem 0;
        }
    }

    @media screen and (max-width: 500px){
        font-size: 0.9rem;

        p {
            margin: 1rem;
        }
    }
}