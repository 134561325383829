#home {
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding-top: 8rem;

    @media screen and (max-width: 700px) {
        padding-top: 5rem;
    }

    @media screen and (max-width: 500px) {
        padding: 3rem 1rem 0rem;
    }
}

.app__header-info {
    flex: 0.1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin-right: 0rem;
    width: 100%;
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        p {
            font-size: 1rem;
            white-space: nowrap;

            @media screen and (max-width: 500px) {
                font-size: 0.75rem;
            }
        }

        @media screen and (max-width: 500px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span {
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 2rem;
        }
    }
}

.app__header-img {
    display: inline-block;
    justify-content: flex;
    align-items: flex;
    position: relative;

    .app__header-img-devops {
        width: 800px;
        padding-top: 15%;
        padding-bottom: 15%;
    }

    img {
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        object-fit: contain;
        z-index: 0;
    }

    div { // Bubbles with Logo
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        position: absolute;
        z-index: 1;

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(2) { // Python
        left: 16rem;
        top: 6rem;
    }

    div:nth-child(3) { // React
        left: 0rem;
        top: 8rem;
    }

    div:nth-child(4) { // npm
        left: -3rem;
        top: 20rem;
    }

    div:nth-child(5) { // Pytest
        left: 6rem;
        top: 30rem;
    }

    div:nth-child(6) { // Eslint
        left: 16rem;
        top: 28rem;
    }

    div:nth-child(7) { // Gitlab CI
        left: 23rem;
        top: 12rem;
    }

    div:nth-child(8) { // Terraform
        left: 30rem;
        top: 6rem;
    }

    div:nth-child(9) { // AWS
        left: 41rem;
        top: 5rem;
    }

    div:nth-child(10) { // Docker
        left: 47rem;
        top: 10rem;
    }

    div:nth-child(11) { // Kubernetes
        left: 48rem;
        top: 21rem;
    }

    div:nth-child(12) { // Prometheus
        left: 41rem;
        top: 29rem;
    }

    div:nth-child(13) { // Grafana
        left: 28rem;
        top: 27rem;
    }

    @media screen and (max-width: 1200px) {

        .app__header-img-devops {
            width: 80%;
            display: block;
        }

        div {
            width: 6vw;
            height: 6vw;
        }

        div:nth-child(2) { // Python
            left: 36%;
            top: 23%;
        }

        div:nth-child(3) { // React
            left: 11%;
            top: 27%;
        }

        div:nth-child(4) { // npm
            left: 5%;
            top: 53%;
        }

        div:nth-child(5) { // Pytest
            left: 20%;
            top: 76%;
        }

        div:nth-child(6) { // Eslint
            left: 37%;
            top: 70%;
        }

        div:nth-child(7) { // Gitlab CI
            left: 48%;
            top: 35%;
        }

        div:nth-child(8) { // Terraform
            left: 58%;
            top: 22%;
        }

        div:nth-child(9) { // AWS
            left: 74%;
            top: 19%;
        }

        div:nth-child(10) { // Docker
            left: 85%;
            top: 32%;
        }

        div:nth-child(11) { // Kubernetes
            left: 87%;
            top: 59%;
        }

        div:nth-child(12) { // Prometheus
            left: 75%;
            top: 73%;
        }

        div:nth-child(13) { // Grafana
            left: 55%;
            top: 70%;
        }
    }

    @media screen and (min-width: 2000px) {

        .app__header-img-devops {
            width: 1000px;
            padding-top: 10%;
        }

        div {
            width: 90px;
            height: 90px;
        }

        div:nth-child(2) { // Python
            left: 20rem;
            top: 4rem;
        }

        div:nth-child(3) { // React
            left: 1rem;
            top: 6rem;
        }

        div:nth-child(4) { // npm
            left: -4rem;
            top: 22rem;
        }

        div:nth-child(5) { // Pytest
            left: 7rem;
            top: 34rem;
        }

        div:nth-child(6) { // Eslint
            left: 21rem;
            top: 31rem;
        }

        div:nth-child(7) { // Gitlab CI
            left: 29rem;
            top: 11rem;
        }

        div:nth-child(8) { // Terraform
            left: 37rem;
            top: 4rem;
        }

        div:nth-child(9) { // AWS
            left: 50rem;
            top: 2rem;
        }

        div:nth-child(10) { // Docker
            left: 58rem;
            top: 9rem;
        }

        div:nth-child(11) { // Kubernetes
            left: 60rem;
            top: 24rem;
        }

        div:nth-child(12) { // Prometheus
            left: 50rem;
            top: 33rem;
        }

        div:nth-child(13) { // Grafana
            left: 36rem;
            top: 32rem;
        }
    }

    @media screen and (max-width: 500px) {
        width: 110%;
    }
}

.app__header-bottom {
    flex: 0.75;
    display: flex;

    div {
        text-align: center;
        font-size: 1.2rem;
        width: 100%;
        padding-top: 5rem;

        span {
            font-size: 1.5rem;
            display: block;
        }

        span:nth-child(2) {
            color: var(--secondary-color);
            -webkit-text-stroke: 1px black;
        }

        span:nth-child(3) {
            color: var(--white-color);
            -webkit-text-stroke: 1px black;
        }

        span:nth-child(4) {
            color: var(--secondary-color);
            -webkit-text-stroke: 1px black;
        }

        @media screen and (min-width: 501px) {
            display: none;
        }
    }

    @media screen and (max-width: 700px) {
        flex: 0.5;
    }

    @media screen and (max-width: 500px) {
        flex: 0.25;
    }
}